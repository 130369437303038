import * as React from 'react';
import { useContext, useState } from 'react';
import { Alert, Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import logo from './ylss-logo.png';

import { Link } from 'react-router-dom';
import { anonAxios } from '../../services/AxiosService';
import { usePasswordFormInput, useTextFormInput } from '../../hooks/FormHooks';
import { AUTH_ACTION_SIGN_IN, AuthContext } from '../../contexts/AuthContext';
import * as AuthenticationService from '../../services/AuthenticationService';

const buildNumber: string = '44-master-b69dd18b';

export const Login: React.FC = () => {
  const email = useTextFormInput('');
  const password = usePasswordFormInput('');
  const [errorMessage, setErrorMessage] = useState('');
  const authContext = useContext(AuthContext);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    anonAxios
      .post('/api/users/login', { email: email.value, plainPassword: password.value })
      .then((response: any) => {
        if (response.data.status === 'OK') {
          AuthenticationService.setJwtInStorage(response.data.token);
          setErrorMessage('');
          authContext.dispatch(AUTH_ACTION_SIGN_IN);
        } else {
          setErrorMessage('Wrong username and/or password');
        }
      })
      .catch((error) => {
        const caughtErrorMessage = error.response && error.response.data && error.response.data.userMessage;
        if (caughtErrorMessage) {
          console.log('An error happened');
          setErrorMessage(caughtErrorMessage);
        }
      });
  };

  const validateForm = () => {
    return email.value.length > 0 && password.value.length > 0;
  };

  return (
    <div className='LoginWrapper'>
      <div className='LogoBar'>
        <img src={logo} style={{ height: 210 }} className='ylss-logo' alt='YLSS Logo' />
      </div>
      <Card>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Email</Label>
              <Input autoFocus={true} {...email} />
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <Input {...password} />
            </FormGroup>

            {errorMessage && <Alert color='danger'>{errorMessage}</Alert>}

            <Button
              block={true}
              color='primary'
              disabled={!validateForm}
              onClick={() => handleSubmit}
              style={{ marginBottom: '1em' }}
              type='submit'
            >
              Login
            </Button>

            <Link to='forgot-password'>Forgot Password?</Link>
          </form>
          {buildNumber.length !== 7 && <span className='small'>{buildNumber}</span>}
        </CardBody>
      </Card>
    </div>
  );
};
