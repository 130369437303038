import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { useContext, useEffect, useState } from 'react';
import { ReportContext } from './ReportContext';
import { authAxios } from '../../services/AxiosService';
import { formatDate } from '../../formatters/DateTimeFormatter';

export const SpecialServices = () => {
  const reportContext = useContext(ReportContext);
  const fromDate = reportContext.fromDate;
  const setFromDate = reportContext.setFromDate;
  const toDate = reportContext.toDate;
  const setToDate = reportContext.setToDate;
  //TODO fix any
  const [specialServicesReportData, setSpecialServicesReportData] = useState<any>();

  useEffect(() => {
    if (fromDate && toDate) {
      authAxios
        .get(`/api/reports/special-services-report?fromDate=${fromDate.getTime()}&toDate=${toDate.getTime()}`)
        .then((response) => {
          setSpecialServicesReportData(response.data);
        });
    }
  }, [fromDate, toDate]);

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Special Services Report</NavbarBrand>
          </Col>
          <Col className={'d-flex'}>
            <Button className={'ml-auto'} color={'warning'} onClick={() => window.print()}>
              Print <FaPrint />
            </Button>
          </Col>
        </Row>
      </Navbar>
      <Container className={'container--no-margin-print'}>
        <Row className={'my-5 '}>
          <Col>
            <Card className={'no-print'}>
              <CardHeader>Select Date Range</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Label className={'flex second-label'} style={{ paddingRight: '10px' }}>
                      From
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={fromDate}
                      onChange={(date) => {
                        if (date) {
                          setFromDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                  <Col>
                    <Label
                      className={'flex second-label'}
                      for={'EndDate'}
                      style={{ paddingRight: '10px', paddingLeft: '10px' }}
                    >
                      To
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={toDate}
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className={'no-print'}>
            <p className={'text-muted'}>
              <b>People Assisted</b> counts any file with an entry in the date range that is either:
              <ul style={{ marginBottom: 0 }}>
                <li>
                  A duty counsel appearance with court type <b>CWC</b>, <b>DVTO</b>, or <b>Youth</b>
                </li>
                <li>
                  A lawyer time entry with activity <b>Court - CWC</b>, <b>Court - DVTO</b>, or <b>Court - Youth</b>
                </li>
              </ul>
              <div>
                Or counts any file with an application date in the date range and has an application / file type of:
                <ul>
                  <li>Mental Health - Full</li>
                  <li>Adult Criminal - Consultation</li>
                  <li>Civil Family - Consultation</li>
                </ul>
              </div>
            </p>
            <p className={'text-muted'}>
              <b>Number of Appearances</b> counts any entry with a date in the date range. An entry is either:
              <ul>
                <li>
                  A duty counsel appearance with court type <b>CWC</b>, <b>DVTO</b>, or <b>Youth</b>
                </li>
                <li>
                  A lawyer time entry on a file with activity <b>Court - CWC</b>, <b>Court - DVTO</b>, or{' '}
                  <b>Court - Youth</b>
                </li>
              </ul>
            </p>
            <p className={'text-muted'}>
              <b>Lawyer hours</b> sums the time tracking hours with a date in the date range and is either:
              <ul>
                <li>
                  A generic lawyer time entry with activity <b>D/C - CWC</b>, <b>D/C - DVTO</b>, or <b>D/C - Youth</b>
                </li>
                <li>
                  A file lawyer time entry with activity <b>Court - CWC</b>, <b>Court - DVTO</b>, or{' '}
                  <b>Court - Youth</b>
                </li>
                <li>
                  A file lawyer time entry on a file with an application / file type of: <b>Mental Health - Full</b>,{' '}
                  <b>Adult Criminal - Consultation</b> or <b>Civil Family - Consultation</b>
                </li>
              </ul>
            </p>
          </Col>
        </Row>
        <div className={'report my-5 mx-3'}>
          <header className={'report-header'}>
            <h2 className={'text-center'}>Special Services Report</h2>
            <h3 className={'text-center h5 text-muted'}>
              {'Between ' + formatDate(fromDate) + ' and ' + formatDate(toDate)}
            </h3>
          </header>
          <table className={'report1'}>
            <thead>
              <tr>
                <th className={'headerspan'} colSpan={2} />
                <th className={'headerspan'}>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={'headerspan'} rowSpan={6}>
                  Number of People Assisted (# of files)
                </th>
                <th className={'header-side'}>CWC</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.peopleServed.CWC : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>DVTO</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.peopleServed.DVTO : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>Youth Court</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.peopleServed.Youth : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>Mental Health - Full</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.peopleServed.MentalHealthFull : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>Adult Criminal - Consultation</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.peopleServed.AdultCriminalConsultation : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>Civil Family - Consultation</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.peopleServed.CivilFamilyConsultation : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'} />
                <th className={'header-side'}>
                  <b>Total</b>
                </th>
                <td className={'total'}>
                  <b>
                    {specialServicesReportData
                      ? specialServicesReportData.peopleServed.CWC +
                        specialServicesReportData.peopleServed.DVTO +
                        specialServicesReportData.peopleServed.Youth +
                        specialServicesReportData.peopleServed.MentalHealthFull +
                        specialServicesReportData.peopleServed.AdultCriminalConsultation +
                        specialServicesReportData.peopleServed.CivilFamilyConsultation
                      : 0}
                  </b>
                </td>
              </tr>
              <tr>
                <th className={'headerspan'} rowSpan={3}>
                  Number of Appearances (# of court entries)
                </th>
                <th className={'header-side'}>CWC</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.numAppearances.CWC : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>DVTO</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.numAppearances.DVTO : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>Youth Court</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.numAppearances.Youth : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'} />
                <th className={'header-side'}>
                  <b>Total</b>
                </th>
                <td className={'total'}>
                  <b>
                    {specialServicesReportData
                      ? specialServicesReportData.numAppearances.CWC +
                        specialServicesReportData.numAppearances.DVTO +
                        specialServicesReportData.numAppearances.Youth
                      : 0}
                  </b>
                </td>
              </tr>
              <tr>
                <th className={'headerspan'} rowSpan={6}>
                  Lawyer Hours (# of hours)
                </th>
                <th className={'header-side'}>CWC</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.lawyerHours.CWC : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>DVTO</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.lawyerHours.DVTO : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>Youth Court</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.lawyerHours.Youth : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>Mental Health - Full</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.lawyerHours.MentalHealthFull : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>Adult Criminal - Consultation</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.lawyerHours.AdultCriminalConsultation : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'}>Civil Family - Consultation</th>
                <td className={'report-row'}>
                  {specialServicesReportData ? specialServicesReportData.lawyerHours.CivilFamilyConsultation : 0}
                </td>
              </tr>
              <tr>
                <th className={'header-side'} />
                <th className={'header-side'}>
                  <b>Total</b>
                </th>
                <td className={'total'}>
                  <b>{specialServicesReportData ? specialServicesReportData.lawyerHours.Total : 0}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
};
