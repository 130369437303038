import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Button, Container, Nav, Navbar, NavbarBrand } from 'reactstrap';
import { FileContext } from './FileContext';
import { DomainContext, IApplicationType } from '../../contexts/DomainContext';
import { FaPrint } from 'react-icons/fa';
import { printLabelLegalAssistant } from '../../services/PrintLabelLegalAssistant';
import { PeopleContext } from '../../contexts/PeopleContext';
import { IndividualsContext } from '../../contexts/IndividualsContext';
import { IOffence } from '../Offences/OffenceTable';

interface ICountedOffences {
  offence: IOffence;
  numberOfInstances: number;
}

export const PrintLegalAssistantLabel: React.FC = () => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const peopleContext = useContext(PeopleContext);
  const individualsContext = useContext(IndividualsContext);
  const lawyers = peopleContext.lawyers;
  const offences = domainContext.offences;
  const MAX_CHARS_WIDE = 40;

  const file = fileContext.file;
  const fileApplicationType = domainContext.applicationTypes.find(
    (e) => e.applicationTypeId === file.applicationTypeId
  )?.name;
  const fileCircuitLocation = domainContext.circuitLocations.find(
    (cl) => cl.circuitLocationId === file.circuitLocationId
  )?.name;
  const fileLawyer = lawyers.find((l) => l.userId === file.lawyerId);
  const applicant = individualsContext.state.individuals.find((i) => i.individualId === file.clientId);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '/DYMO.Label.Framework.latest.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const orderedOffences = () => {
    let groups: { groupName: string; applicationType?: IApplicationType; offenceGroupSeriousness?: number }[] = [];
    file.charges.forEach((c) => {
      const offence = offences.find((o) => o.offenceId === c.offenceId);
      const applicationType = domainContext.applicationTypes.find(
        (at) => at.applicationTypeId === offence?.applicationTypeId
      );
      const groupName = (applicationType?.name ?? '') + (offence?.group ?? '');
      const group = {
        groupName: groupName,
        applicationType: applicationType,
        offenceGroupSeriousness: offence?.group,
      };
      if (!groups.map((g) => g.groupName).find((gn) => gn === groupName)) {
        groups = groups.concat(group);
      }
    });
    groups = groups.sort(
      (
        a: {
          groupName: string;
          applicationType?: IApplicationType;
          offenceGroupSeriousness?: number;
        },
        b: {
          groupName: string;
          applicationType?: IApplicationType;
          offenceGroupSeriousness?: number;
        }
      ) => a.groupName.localeCompare(b.groupName)
    );
    let sortedOffences: IOffence[] = [];
    groups.forEach((group) => {
      let fileOffences: IOffence[] = [];
      file.charges.forEach((c) => {
        const offence = offences.find((o) => o.offenceId === c.offenceId);
        if (offence !== undefined) {
          fileOffences = fileOffences.concat(offence);
        }
      });
      const fileOffencesInGroup = fileOffences.filter(
        (o) =>
          o.applicationTypeId === group.applicationType?.applicationTypeId && o.group === group.offenceGroupSeriousness
      );
      const sortedGroupFileOffences = fileOffencesInGroup.sort((a: IOffence, b: IOffence) =>
        a.number.localeCompare(b.number)
      );
      sortedOffences = sortedOffences.concat(sortedGroupFileOffences);
    });
    return sortedOffences;
  };

  const chargesToDisplay = () => {
    let countedOffences: ICountedOffences[] = [];
    orderedOffences().forEach((o) => {
      if (countedOffences.find((co) => co.offence && co.offence.offenceId === o.offenceId)) {
        countedOffences = countedOffences.map((co) => {
          if (co.offence.offenceId === o.offenceId) {
            return { offence: co.offence, numberOfInstances: co.numberOfInstances + 1 } as ICountedOffences;
          } else {
            return co;
          }
        });
      } else {
        countedOffences = countedOffences.concat({ offence: o, numberOfInstances: 1 } as ICountedOffences);
      }
    });
    return countedOffences
      .map((co) => {
        const offenceName = co.offence.number.replace('CCC', '').trim();
        if (co.numberOfInstances > 1) {
          return `${offenceName} x${co.numberOfInstances}`;
        } else {
          return offenceName;
        }
      })
      .join(', ');
  };

  const firstRowText = () => {
    const nameText = applicant?.lastName?.toUpperCase() + ', ' + applicant?.firstName;
    const officeNumberText = file.lawOfficeNumber ? '#' + file.lawOfficeNumber : '';
    const firstRowString = nameText + officeNumberText;
    if (firstRowString.length > MAX_CHARS_WIDE) {
      return nameText.slice(0, MAX_CHARS_WIDE - 3 - officeNumberText.length) + '... ' + officeNumberText;
    } else {
      const numSpaces = MAX_CHARS_WIDE - firstRowString.length;
      let result = nameText;
      for (let i = 0; i < numSpaces; i++) {
        result += '\xa0';
      }
      return result + officeNumberText;
    }
  };

  const secondRowText = () => {
    return fileApplicationType + (fileCircuitLocation ? ' - ' + fileCircuitLocation : '');
  };

  const thirdRowText = () => {
    const courtFileNum = file.courtNumber ? file.courtNumber + ' - ' : '';
    const thirdRowString = courtFileNum + chargesToDisplay();
    if (thirdRowString.length > MAX_CHARS_WIDE) {
      return thirdRowString.slice(0, MAX_CHARS_WIDE - 2) + '...';
    } else {
      return thirdRowString;
    }
  };

  const fourthRowText = () => {
    return fileLawyer?.lastName.toUpperCase() + ', ' + fileLawyer?.firstName;
  };

  return (
    <div>
      <div className='d-print-none'>
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom'}>
          <NavbarBrand>{'Print Label'}</NavbarBrand>
          <Nav className={'ml-auto'}>
            <Button
              color='warning'
              type='submit'
              onClick={() => {
                printLabelLegalAssistant(firstRowText(), secondRowText(), thirdRowText(), fourthRowText());
              }}
            >
              <FaPrint /> Print
            </Button>
          </Nav>
        </Navbar>
      </div>
      <Container fluid={true} className={'my-3 container--no-margin-print'}>
        <div className='report' style={{ padding: '15px' }}>
          <b>{firstRowText()}</b>
          <br />
          {secondRowText()}
          <br />
          {thirdRowText()}
          <br />
          <b>{fourthRowText()}</b>
        </div>
      </Container>
    </div>
  );
};
