/* eslint-disable */
const loadPrinter = () => {
  const x = window.dymo.label.framework.init;
  console.log(x);
};
const printLabelLegalAssistant = (text1, text2, text3, text4) => {
  try {
    const x = window.dymo.label.framework.init;
    console.log(x);
    // open label
    const labelXml =
      '<?xml version="1.0" encoding="utf-8"?>\
            <DieCutLabel Version="8.0" Units="twips">\
                <PaperOrientation>Landscape</PaperOrientation>\
                <Id>Address</Id>\
                <PaperName>30252 Address</PaperName>\
                <DrawCommands/>\
                <ObjectInfo>\
                    <AddressObject>\
                        <Name>Text</Name>\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
                        <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
                        <LinkedObjectName></LinkedObjectName>\
                        <Rotation>Rotation0</Rotation>\
                        <IsMirrored>False</IsMirrored>\
                        <IsVariable>True</IsVariable>\
                        <HorizontalAlignment>Left</HorizontalAlignment>\
                        <VerticalAlignment>Middle</VerticalAlignment>\
                        <TextFitMode>ShrinkToFit</TextFitMode>\
                        <UseFullFontHeight>True</UseFullFontHeight>\
                        <Verticalized>False</Verticalized>\
                           <StyledText>\
                           <Element>\
                               <String>' +
      text1 +
      '\n' +
      '</String>\
                               <Attributes>\
                                   <Font Family="Arial" Size="12" Bold="True" Italic="False"\
                                               Underline="False" Strikeout="False" />\
                                   <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
                               </Attributes>\
                           </Element>\
                           <Element>\
                               <String>' +
      text2 +
      '\n' +
      '</String>\
                               <Attributes>\
                                   <Font Family="Arial" Size="12" Bold="False" Italic="False"\
                                               Underline="False" Strikeout="False" />\
                                   <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
                               </Attributes>\
                           </Element>\
                                              <Element>\
                               <String>' +
      text3 +
      '\n' +
      '</String>\
                               <Attributes>\
                                   <Font Family="Arial" Size="12" Bold="False" Italic="False"\
                                               Underline="False" Strikeout="False" />\
                                   <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
                               </Attributes>\
                           </Element>\
                                              <Element>\
                               <String>' +
      text4 +
      '</String>\
                               <Attributes>\
                                   <Font Family="Arial" Size="12" Bold="True" Italic="False"\
                                               Underline="False" Strikeout="False" />\
                                   <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
                               </Attributes>\
                           </Element>\
                           </StyledText>\
                        <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>\
                        <BarcodePosition>Suppress</BarcodePosition>\
                        <LineFonts/>\
                    </AddressObject>\
                    <Bounds X="332" Y="150" Width="4455" Height="1260" />\
                </ObjectInfo>\
            </DieCutLabel>';
    const label = window.dymo.label.framework.openLabelXml(labelXml);
    console.log(labelXml);
    // set label text
    //label.setObjectText('Text', text);

    // select printer to print on
    // for simplicity sake just use the first LabelWriter printer
    const printers = window.dymo.label.framework.getPrinters();
    if (printers.length === 0) {
      throw 'No DYMO printers are installed. Install DYMO printers.';
    }

    let printerName = '';
    for (let i = 0; i < printers.length; ++i) {
      const printer = printers[i];
      if (printer.printerType === 'LabelWriterPrinter') {
        printerName = printer.name;
        break;
      }
    }
    console.log(printerName);
    if (printerName === '') throw 'No LabelWriter printers found. Install LabelWriter printer';

    // finally print the label
    label.print(printerName);
  } catch (e) {
    alert(e.message || e);
    console.log(e.message || e);
  }
};
export { loadPrinter, printLabelLegalAssistant };
