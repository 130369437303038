import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  CustomInput,
  Label,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import { IReportingFile } from '../Files/FileContext';
import {
  DomainContext,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_OTHER,
  ICircuitLocation,
  IReason,
  STAGE_APPROVED,
  STAGE_ASSIGNED_TO_LAWYER,
  STAGE_BOARD_APPROVED,
  STAGE_BOARD_REFUSED,
  STAGE_BOARD_REVIEW,
  STAGE_CANCELLED_COUNSEL_REQUEST,
  STAGE_CLOSED,
  STAGE_COUNSEL_REQUEST,
  STAGE_COUNSEL_REQUEST_DRAFT,
  STAGE_DUTY_COUNSEL,
  STAGE_ERROR,
  STAGE_LAWYER_REQUESTED,
  STAGE_REFUSED,
  STAGE_RETURN_FOR_CONFLICT,
  STAGE_STOPPED,
} from 'src/contexts/DomainContext';
import { IndividualsContext } from 'src/contexts/IndividualsContext';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { ReportContext } from './ReportContext';
import { formatDate } from 'src/formatters/DateTimeFormatter';
import { MyUserContext } from 'src/contexts/MyUserContext';

export const Applications = () => {
  const individualsContext = useContext(IndividualsContext);
  const domainContext = useContext(DomainContext);
  const reportContext = useContext(ReportContext);
  const myUserContext = useContext(MyUserContext);
  const circuitLocations = domainContext.circuitLocations;
  const allFiles = reportContext.allFiles;
  const fromDate = reportContext.fromDate;
  const setFromDate = reportContext.setFromDate;
  const toDate = reportContext.toDate;
  const setToDate = reportContext.setToDate;
  const [filteredFiles, setFilteredFiles] = useState<IReportingFile[]>([]);
  const [total, setTotal] = useState<IReportingFile[]>([]);
  const [missingDataFiles, setMissingDataFiles] = useState<IReportingFile[]>([]);
  const [refusedFiles, setRefusedFiles] = useState<IReportingFile[]>([]);
  const [showLocationBreakdown, setShowLocationBreakdown] = useState(false);

  const approvedStages = [
    STAGE_APPROVED,
    STAGE_LAWYER_REQUESTED,
    STAGE_ASSIGNED_TO_LAWYER,
    STAGE_BOARD_APPROVED,
    STAGE_RETURN_FOR_CONFLICT,
    STAGE_CLOSED,
  ];
  const refusedStages = [STAGE_REFUSED, STAGE_BOARD_REFUSED, STAGE_BOARD_REVIEW];

  const isRefusedReason = (file: IReportingFile, reasonId: string) => {
    if (file.sId === STAGE_BOARD_REFUSED) {
      return file.rsnIdBrdRej === reasonId;
    } else if (file.sId === STAGE_BOARD_REVIEW || file.sId === STAGE_REFUSED) {
      return file.rsnId === reasonId;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let totalFiltered: IReportingFile[] = [];
    allFiles.forEach((f) => {
      if (
        f.appDate &&
        f.appDate >= fromDate.getTime() &&
        f.appDate <= toDate.getTime() &&
        f.sId !== STAGE_STOPPED &&
        f.sId !== STAGE_ERROR &&
        f.sId !== STAGE_DUTY_COUNSEL &&
        f.sId !== STAGE_COUNSEL_REQUEST_DRAFT &&
        f.sId !== STAGE_COUNSEL_REQUEST &&
        f.sId !== STAGE_CANCELLED_COUNSEL_REQUEST
      ) {
        totalFiltered = totalFiltered.concat(f);
      }
    });
    //filter duplicate files
    totalFiltered = totalFiltered.filter((f) => {
      if (f.fNum !== undefined) {
        const duplicates: IReportingFile[] = totalFiltered.filter(
          (file) => file.fNum !== undefined && file.fNum === f.fNum
        );
        if (duplicates.length > 0) {
          if (!duplicates.some((file) => file.fSeqNum > f.fSeqNum)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    let missingGenderOrBirthDate: IReportingFile[] = [];
    setFilteredFiles(totalFiltered);
    totalFiltered.forEach((f) => {
      const client = individualsContext.state.individuals.find((i) => i.individualId === f.cId);
      if (!client || !client.birthDate || !client.genderId) {
        missingGenderOrBirthDate = missingGenderOrBirthDate.concat(f);
      }
    });
    setMissingDataFiles(missingGenderOrBirthDate);
    setTotal(totalFiltered);
    setRefusedFiles(totalFiltered.filter((f) => f.sId === STAGE_REFUSED));
    // These dependencies should be reviewed and corrected, this was disabled only to clean up lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFiles, fromDate, toDate]);

  const dateDiffInYears = (dateOld: Date, dateNew: Date) => {
    const ynew = dateNew.getFullYear();
    const mnew = dateNew.getMonth();
    const dnew = dateNew.getDate();
    const yold = dateOld.getFullYear();
    const mold = dateOld.getMonth();
    const dold = dateOld.getDate();
    let diff = Math.abs(ynew - yold);
    if (mold > mnew) {
      diff = diff - 1;
    } else {
      if (mold === mnew) {
        if (dold > dnew) {
          diff = diff - 1;
        }
      }
    }
    return diff;
  };

  const genericTable = () => {
    let totalMaleAgeGroup1Indigenous: IReportingFile[] = [];
    let totalMaleAgeGroup1Non: IReportingFile[] = [];
    let totalMaleAgeGroup2Indigenous: IReportingFile[] = [];
    let totalMaleAgeGroup2Non: IReportingFile[] = [];
    let totalMaleAgeGroup3Indigenous: IReportingFile[] = [];
    let totalMaleAgeGroup3Non: IReportingFile[] = [];
    let totalMaleAgeGroup4Indigenous: IReportingFile[] = [];
    let totalMaleAgeGroup4Non: IReportingFile[] = [];
    let totalMaleAgeGroup5Indigenous: IReportingFile[] = [];
    let totalMaleAgeGroup5Non: IReportingFile[] = [];

    let totalFemaleAgeGroup1Indigenous: IReportingFile[] = [];
    let totalFemaleAgeGroup1Non: IReportingFile[] = [];
    let totalFemaleAgeGroup2Indigenous: IReportingFile[] = [];
    let totalFemaleAgeGroup2Non: IReportingFile[] = [];
    let totalFemaleAgeGroup3Indigenous: IReportingFile[] = [];
    let totalFemaleAgeGroup3Non: IReportingFile[] = [];
    let totalFemaleAgeGroup4Indigenous: IReportingFile[] = [];
    let totalFemaleAgeGroup4Non: IReportingFile[] = [];
    let totalFemaleAgeGroup5Indigenous: IReportingFile[] = [];
    let totalFemaleAgeGroup5Non: IReportingFile[] = [];

    let totalOtherAgeGroup1Indigenous: IReportingFile[] = [];
    let totalOtherAgeGroup1Non: IReportingFile[] = [];
    let totalOtherAgeGroup2Indigenous: IReportingFile[] = [];
    let totalOtherAgeGroup2Non: IReportingFile[] = [];
    let totalOtherAgeGroup3Indigenous: IReportingFile[] = [];
    let totalOtherAgeGroup3Non: IReportingFile[] = [];
    let totalOtherAgeGroup4Indigenous: IReportingFile[] = [];
    let totalOtherAgeGroup4Non: IReportingFile[] = [];
    let totalOtherAgeGroup5Indigenous: IReportingFile[] = [];
    let totalOtherAgeGroup5Non: IReportingFile[] = [];

    let unknownAgeGender: IReportingFile[] = [];

    filteredFiles
      .filter((f) => (showLocationBreakdown ? f.cirLocId === undefined : true))
      .forEach((f) => {
        const client = individualsContext.state.individuals.find((i) => i.individualId === f.cId);
        if (client) {
          const age = client.birthDate ? dateDiffInYears(new Date(client.birthDate), new Date(f.appDate)) : undefined;
          if (client.genderId && client.genderId === GENDER_MALE) {
            if (age !== undefined && age < 18) {
              if (client.indigenous || client.visibleMinority) {
                totalMaleAgeGroup1Indigenous = totalMaleAgeGroup1Indigenous.concat(f);
              } else {
                totalMaleAgeGroup1Non = totalMaleAgeGroup1Non.concat(f);
              }
            } else if (age !== undefined && age >= 18 && age <= 29) {
              if (client.indigenous || client.visibleMinority) {
                totalMaleAgeGroup2Indigenous = totalMaleAgeGroup2Indigenous.concat(f);
              } else {
                totalMaleAgeGroup2Non = totalMaleAgeGroup2Non.concat(f);
              }
            } else if (age !== undefined && age >= 30 && age <= 34) {
              if (client.indigenous || client.visibleMinority) {
                totalMaleAgeGroup3Indigenous = totalMaleAgeGroup3Indigenous.concat(f);
              } else {
                totalMaleAgeGroup3Non = totalMaleAgeGroup3Non.concat(f);
              }
            } else if (age !== undefined && age >= 35 && age <= 49) {
              if (client.indigenous || client.visibleMinority) {
                totalMaleAgeGroup4Indigenous = totalMaleAgeGroup4Indigenous.concat(f);
              } else {
                totalMaleAgeGroup4Non = totalMaleAgeGroup4Non.concat(f);
              }
            } else if (age !== undefined && age >= 50) {
              if (client.indigenous || client.visibleMinority) {
                totalMaleAgeGroup5Indigenous = totalMaleAgeGroup5Indigenous.concat(f);
              } else {
                totalMaleAgeGroup5Non = totalMaleAgeGroup5Non.concat(f);
              }
            } else {
              unknownAgeGender = unknownAgeGender.concat(f);
            }
          } else if (client.genderId && client.genderId === GENDER_FEMALE) {
            if (age !== undefined && age < 18) {
              if (client.indigenous || client.visibleMinority) {
                totalFemaleAgeGroup1Indigenous = totalFemaleAgeGroup1Indigenous.concat(f);
              } else {
                totalFemaleAgeGroup1Non = totalFemaleAgeGroup1Non.concat(f);
              }
            } else if (age !== undefined && age >= 18 && age <= 29) {
              if (client.indigenous || client.visibleMinority) {
                totalFemaleAgeGroup2Indigenous = totalFemaleAgeGroup2Indigenous.concat(f);
              } else {
                totalFemaleAgeGroup2Non = totalFemaleAgeGroup2Non.concat(f);
              }
            } else if (age !== undefined && age >= 30 && age <= 34) {
              if (client.indigenous || client.visibleMinority) {
                totalFemaleAgeGroup3Indigenous = totalFemaleAgeGroup3Indigenous.concat(f);
              } else {
                totalFemaleAgeGroup3Non = totalFemaleAgeGroup3Non.concat(f);
              }
            } else if (age !== undefined && age >= 35 && age <= 49) {
              if (client.indigenous || client.visibleMinority) {
                totalFemaleAgeGroup4Indigenous = totalFemaleAgeGroup4Indigenous.concat(f);
              } else {
                totalFemaleAgeGroup4Non = totalFemaleAgeGroup4Non.concat(f);
              }
            } else if (age !== undefined && age >= 50) {
              if (client.indigenous || client.visibleMinority) {
                totalFemaleAgeGroup5Indigenous = totalFemaleAgeGroup5Indigenous.concat(f);
              } else {
                totalFemaleAgeGroup5Non = totalFemaleAgeGroup5Non.concat(f);
              }
            } else {
              unknownAgeGender = unknownAgeGender.concat(f);
            }
          } else if (client.genderId && client.genderId === GENDER_OTHER) {
            if (age !== undefined && age < 18) {
              if (client.indigenous || client.visibleMinority) {
                totalOtherAgeGroup1Indigenous = totalOtherAgeGroup1Indigenous.concat(f);
              } else {
                totalOtherAgeGroup1Non = totalOtherAgeGroup1Non.concat(f);
              }
            } else if (age !== undefined && age >= 18 && age <= 29) {
              if (client.indigenous || client.visibleMinority) {
                totalOtherAgeGroup2Indigenous = totalOtherAgeGroup2Indigenous.concat(f);
              } else {
                totalOtherAgeGroup2Non = totalOtherAgeGroup2Non.concat(f);
              }
            } else if (age !== undefined && age >= 30 && age <= 34) {
              if (client.indigenous || client.visibleMinority) {
                totalOtherAgeGroup3Indigenous = totalOtherAgeGroup3Indigenous.concat(f);
              } else {
                totalOtherAgeGroup3Non = totalOtherAgeGroup3Non.concat(f);
              }
            } else if (age !== undefined && age >= 35 && age <= 49) {
              if (client.indigenous || client.visibleMinority) {
                totalOtherAgeGroup4Indigenous = totalOtherAgeGroup4Indigenous.concat(f);
              } else {
                totalOtherAgeGroup4Non = totalOtherAgeGroup4Non.concat(f);
              }
            } else if (age !== undefined && age >= 50) {
              if (client.indigenous || client.visibleMinority) {
                totalOtherAgeGroup5Indigenous = totalOtherAgeGroup5Indigenous.concat(f);
              } else {
                totalOtherAgeGroup5Non = totalOtherAgeGroup5Non.concat(f);
              }
            } else {
              unknownAgeGender = unknownAgeGender.concat(f);
            }
          } else {
            unknownAgeGender = unknownAgeGender.concat(f);
          }
        }
      });
    const totalMale = totalMaleAgeGroup1Indigenous
      .concat(totalMaleAgeGroup1Non)
      .concat(totalMaleAgeGroup2Indigenous)
      .concat(totalMaleAgeGroup2Non)
      .concat(totalMaleAgeGroup3Indigenous)
      .concat(totalMaleAgeGroup3Non)
      .concat(totalMaleAgeGroup4Indigenous)
      .concat(totalMaleAgeGroup4Non)
      .concat(totalMaleAgeGroup5Indigenous)
      .concat(totalMaleAgeGroup5Non);
    const totalFemale = totalFemaleAgeGroup1Indigenous
      .concat(totalFemaleAgeGroup1Non)
      .concat(totalFemaleAgeGroup2Indigenous)
      .concat(totalFemaleAgeGroup2Non)
      .concat(totalFemaleAgeGroup3Indigenous)
      .concat(totalFemaleAgeGroup3Non)
      .concat(totalFemaleAgeGroup4Indigenous)
      .concat(totalFemaleAgeGroup4Non)
      .concat(totalFemaleAgeGroup5Indigenous)
      .concat(totalFemaleAgeGroup5Non);
    const totalOther = totalOtherAgeGroup1Indigenous
      .concat(totalOtherAgeGroup1Non)
      .concat(totalOtherAgeGroup2Indigenous)
      .concat(totalOtherAgeGroup2Non)
      .concat(totalOtherAgeGroup3Indigenous)
      .concat(totalOtherAgeGroup3Non)
      .concat(totalOtherAgeGroup4Indigenous)
      .concat(totalOtherAgeGroup4Non)
      .concat(totalOtherAgeGroup5Indigenous)
      .concat(totalOtherAgeGroup5Non);

    return (
      <>
        <tr>
          {showLocationBreakdown && (
            <th className={'header-side'} rowSpan={9}>
              Undefined Location
            </th>
          )}
          <th className={'header-side'}>
            <b>Received</b>
          </th>
          <td className={'total'}>{totalMaleAgeGroup1Indigenous.length}</td>
          <td className={'total'}>{totalMaleAgeGroup1Non.length}</td>
          <td className={'total'}>{totalMaleAgeGroup2Indigenous.length}</td>
          <td className={'total'}>{totalMaleAgeGroup2Non.length}</td>
          <td className={'total'}>{totalMaleAgeGroup3Indigenous.length}</td>
          <td className={'total'}>{totalMaleAgeGroup3Non.length}</td>
          <td className={'total'}>{totalMaleAgeGroup4Indigenous.length}</td>
          <td className={'total'}>{totalMaleAgeGroup4Non.length}</td>
          <td className={'total'}>{totalMaleAgeGroup5Indigenous.length}</td>
          <td className={'total'}>{totalMaleAgeGroup5Non.length}</td>
          <td className={'total'}>
            <b>{totalMale.length}</b>
          </td>
          <td className={'total'}>{totalFemaleAgeGroup1Indigenous.length}</td>
          <td className={'total'}>{totalFemaleAgeGroup1Non.length}</td>
          <td className={'total'}>{totalFemaleAgeGroup2Indigenous.length}</td>
          <td className={'total'}>{totalFemaleAgeGroup2Non.length}</td>
          <td className={'total'}>{totalFemaleAgeGroup3Indigenous.length}</td>
          <td className={'total'}>{totalFemaleAgeGroup3Non.length}</td>
          <td className={'total'}>{totalFemaleAgeGroup4Indigenous.length}</td>
          <td className={'total'}>{totalFemaleAgeGroup4Non.length}</td>
          <td className={'total'}>{totalFemaleAgeGroup5Indigenous.length}</td>
          <td className={'total'}>{totalFemaleAgeGroup5Non.length}</td>
          <td className={'total'}>
            <b>{totalFemale.length}</b>
          </td>
          <td className={'total'}>{totalOtherAgeGroup1Indigenous.length}</td>
          <td className={'total'}>{totalOtherAgeGroup1Non.length}</td>
          <td className={'total'}>{totalOtherAgeGroup2Indigenous.length}</td>
          <td className={'total'}>{totalOtherAgeGroup2Non.length}</td>
          <td className={'total'}>{totalOtherAgeGroup3Indigenous.length}</td>
          <td className={'total'}>{totalOtherAgeGroup3Non.length}</td>
          <td className={'total'}>{totalOtherAgeGroup4Indigenous.length}</td>
          <td className={'total'}>{totalOtherAgeGroup4Non.length}</td>
          <td className={'total'}>{totalOtherAgeGroup5Indigenous.length}</td>
          <td className={'total'}>{totalOtherAgeGroup5Non.length}</td>
          <td className={'total'}>
            <b>{totalOther.length}</b>
          </td>
          <td className={'total'}>{unknownAgeGender.length}</td>
          <td className={'total'}>
            <b>{totalMale.length + totalFemale.length + totalOther.length + unknownAgeGender.length}</b>
          </td>
        </tr>
        <tr>
          <th className={'header-side'}>
            <b>Approved</b>
          </th>
          <td className={'total'}>
            {totalMaleAgeGroup1Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalMaleAgeGroup1Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalMaleAgeGroup2Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalMaleAgeGroup2Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalMaleAgeGroup3Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalMaleAgeGroup3Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalMaleAgeGroup4Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalMaleAgeGroup4Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalMaleAgeGroup5Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalMaleAgeGroup5Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            <b>{totalMale.filter((f) => approvedStages.includes(f.sId)).length}</b>
          </td>
          <td className={'total'}>
            {totalFemaleAgeGroup1Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalFemaleAgeGroup1Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalFemaleAgeGroup2Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalFemaleAgeGroup2Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalFemaleAgeGroup3Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalFemaleAgeGroup3Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalFemaleAgeGroup4Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalFemaleAgeGroup4Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalFemaleAgeGroup5Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalFemaleAgeGroup5Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            <b>{totalFemale.filter((f) => approvedStages.includes(f.sId)).length}</b>
          </td>
          <td className={'total'}>
            {totalOtherAgeGroup1Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalOtherAgeGroup1Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalOtherAgeGroup2Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalOtherAgeGroup2Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalOtherAgeGroup3Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalOtherAgeGroup3Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalOtherAgeGroup4Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalOtherAgeGroup4Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalOtherAgeGroup5Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalOtherAgeGroup5Non.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            <b>{totalOther.filter((f) => approvedStages.includes(f.sId)).length}</b>
          </td>
          <td className={'total'}>{unknownAgeGender.filter((f) => approvedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            <b>
              {
                totalMale
                  .concat(totalFemale)
                  .concat(totalOther)
                  .concat(unknownAgeGender)
                  .filter((f) => approvedStages.includes(f.sId)).length
              }
            </b>
          </td>
        </tr>
        {domainContext.reasons.map((reason: IReason) => {
          return (
            <tr key={reason.reasonId}>
              <th className={'header-side'}>{'Refused - ' + reason.name}</th>
              <td className={'report-row'}>
                {totalMaleAgeGroup1Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalMaleAgeGroup1Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalMaleAgeGroup2Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalMaleAgeGroup2Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalMaleAgeGroup3Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalMaleAgeGroup3Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalMaleAgeGroup4Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalMaleAgeGroup4Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalMaleAgeGroup5Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalMaleAgeGroup5Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'total'}>
                <b>{totalMale.filter((f) => isRefusedReason(f, reason.reasonId)).length}</b>
              </td>
              <td className={'report-row'}>
                {totalFemaleAgeGroup1Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalFemaleAgeGroup1Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalFemaleAgeGroup2Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalFemaleAgeGroup2Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalFemaleAgeGroup3Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalFemaleAgeGroup3Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalFemaleAgeGroup4Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalFemaleAgeGroup4Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalFemaleAgeGroup5Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalFemaleAgeGroup5Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'total'}>
                <b>{totalFemale.filter((f) => isRefusedReason(f, reason.reasonId)).length}</b>
              </td>
              <td className={'report-row'}>
                {totalOtherAgeGroup1Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalOtherAgeGroup1Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalOtherAgeGroup2Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalOtherAgeGroup2Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalOtherAgeGroup3Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalOtherAgeGroup3Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalOtherAgeGroup4Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalOtherAgeGroup4Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalOtherAgeGroup5Indigenous.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'report-row'}>
                {totalOtherAgeGroup5Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
              </td>
              <td className={'total'}>
                <b>{totalOther.filter((f) => isRefusedReason(f, reason.reasonId)).length}</b>
              </td>
              <td className={'total'}>{unknownAgeGender.filter((f) => isRefusedReason(f, reason.reasonId)).length}</td>
              <td className={'total'}>
                <b>
                  {
                    totalMale
                      .concat(totalFemale)
                      .concat(totalOther)
                      .concat(unknownAgeGender)
                      .filter((f) => isRefusedReason(f, reason.reasonId)).length
                  }
                </b>
              </td>
            </tr>
          );
        })}
        {/*{blankRow}*/}
        <tr>
          <th className={'header-side'}>
            <b>Total Refused</b>
          </th>
          <td className={'total'}>
            {totalMaleAgeGroup1Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalMaleAgeGroup1Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalMaleAgeGroup2Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalMaleAgeGroup2Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalMaleAgeGroup3Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalMaleAgeGroup3Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalMaleAgeGroup4Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalMaleAgeGroup4Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalMaleAgeGroup5Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalMaleAgeGroup5Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            <b>{totalMale.filter((f) => refusedStages.includes(f.sId)).length}</b>
          </td>
          <td className={'total'}>
            {totalFemaleAgeGroup1Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalFemaleAgeGroup1Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalFemaleAgeGroup2Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalFemaleAgeGroup2Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalFemaleAgeGroup3Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalFemaleAgeGroup3Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalFemaleAgeGroup4Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalFemaleAgeGroup4Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalFemaleAgeGroup5Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalFemaleAgeGroup5Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            <b>{totalFemale.filter((f) => refusedStages.includes(f.sId)).length}</b>
          </td>
          <td className={'total'}>
            {totalOtherAgeGroup1Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalOtherAgeGroup1Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalOtherAgeGroup2Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalOtherAgeGroup2Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalOtherAgeGroup3Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalOtherAgeGroup3Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalOtherAgeGroup4Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalOtherAgeGroup4Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            {totalOtherAgeGroup5Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
          </td>
          <td className={'total'}>{totalOtherAgeGroup5Non.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            <b>{totalOther.filter((f) => refusedStages.includes(f.sId)).length}</b>
          </td>
          <td className={'total'}>{unknownAgeGender.filter((f) => refusedStages.includes(f.sId)).length}</td>
          <td className={'total'}>
            <b>
              {
                totalMale
                  .concat(totalFemale)
                  .concat(totalOther)
                  .concat(unknownAgeGender)
                  .filter((f) => refusedStages.includes(f.sId)).length
              }
            </b>
          </td>
        </tr>
      </>
    );
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Applications</NavbarBrand>
          </Col>
          <Col className={'d-flex'}>
            <Button className={'ml-auto'} color={'warning'} onClick={() => window.print()}>
              Print <FaPrint />
            </Button>
          </Col>
        </Row>
      </Navbar>
      <Container className={'container--no-margin-print'}>
        <Row className={'my-5 '}>
          <Col>
            <Card className={'no-print'}>
              <CardHeader>Select File Application Date Range</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Label className={'flex second-label'} style={{ paddingRight: '10px' }}>
                      From
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={fromDate}
                      onChange={(date) => {
                        if (date) {
                          setFromDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                  <Col>
                    <Label
                      className={'flex second-label'}
                      for={'EndDate'}
                      style={{ paddingRight: '10px', paddingLeft: '10px' }}
                    >
                      To
                    </Label>
                    <DatePicker
                      className={'form-control date-select flex'}
                      selected={toDate}
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        }
                      }}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      shouldCloseOnSelect={true}
                      dateFormat={'yyyy-MM-dd'}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <CustomInput
              className={'mt-2'}
              type='checkbox'
              id={'showLocationBreakdown'}
              name={'showLocationBreakdown'}
              label={'Show Circuit Location Breakdown'}
              checked={showLocationBreakdown}
              onChange={() => {
                setShowLocationBreakdown(!showLocationBreakdown);
              }}
            />
          </Col>
          <Col className={'no-print'}>
            <p className={'text-muted'}>
              *This report includes all files with application dates in the range selected. It does not include:
              <ul>
                <li>Stopped Files</li>
                <li>Error Files</li>
                <li>Files in Duty Counsel Stage</li>
                <li>Counsel Requests without Intake Approval</li>
                <li>Client with no birthdate (is included under unknown)</li>
                <li>Client with no gender (is included under unknown)</li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
      <div className={'report mt-5 mx-3'}>
        <header className={'report-header'}>
          <h2 className={'text-center'}>Applications</h2>
          <h3 className={'text-center h5 text-muted'}>
            {'Total files in table: ' +
              total.length +
              ' for dates between: ' +
              formatDate(fromDate) +
              ' and ' +
              formatDate(toDate)}
          </h3>
        </header>

        <table className={'report1'}>
          <thead>
            <tr>
              <th className={'header-side'} colSpan={showLocationBreakdown ? 2 : 1} rowSpan={3} />
              <th className={'header'} colSpan={11}>
                Male
              </th>
              <th className={'header'} colSpan={11}>
                Female
              </th>
              <th className={'header'} colSpan={11}>
                Other
              </th>
              <th className={'header'} rowSpan={3}>
                Unknown
              </th>
              <th className={'header'} rowSpan={3}>
                Total
              </th>
            </tr>
            <tr>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'} />
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'} />
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>17 & Under</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>18 - 29</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>30 - 34</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>35 - 49</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'}>50 & Above</th>
              <th className={'headerspan-row1'} />
            </tr>
            <tr>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'} />
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'} />
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'}>Indig. & Vis Min.</th>
              <th className={'headerspan-row2'}>Non</th>
              <th className={'headerspan-row2'} />
            </tr>
          </thead>
          <tbody>
            {showLocationBreakdown ? (
              <>
                {circuitLocations
                  .sort((a: ICircuitLocation, b: ICircuitLocation) =>
                    b.name.toUpperCase().localeCompare(a.name.toUpperCase())
                  )
                  .map((c) => {
                    const locationFiles = filteredFiles.filter((ff) => ff.cirLocId === c.circuitLocationId);

                    let locationMaleAgeGroup1Indigenous: IReportingFile[] = [];
                    let locationMaleAgeGroup1Non: IReportingFile[] = [];
                    let locationMaleAgeGroup2Indigenous: IReportingFile[] = [];
                    let locationMaleAgeGroup2Non: IReportingFile[] = [];
                    let locationMaleAgeGroup3Indigenous: IReportingFile[] = [];
                    let locationMaleAgeGroup3Non: IReportingFile[] = [];
                    let locationMaleAgeGroup4Indigenous: IReportingFile[] = [];
                    let locationMaleAgeGroup4Non: IReportingFile[] = [];
                    let locationMaleAgeGroup5Indigenous: IReportingFile[] = [];
                    let locationMaleAgeGroup5Non: IReportingFile[] = [];

                    let locationFemaleAgeGroup1Indigenous: IReportingFile[] = [];
                    let locationFemaleAgeGroup1Non: IReportingFile[] = [];
                    let locationFemaleAgeGroup2Indigenous: IReportingFile[] = [];
                    let locationFemaleAgeGroup2Non: IReportingFile[] = [];
                    let locationFemaleAgeGroup3Indigenous: IReportingFile[] = [];
                    let locationFemaleAgeGroup3Non: IReportingFile[] = [];
                    let locationFemaleAgeGroup4Indigenous: IReportingFile[] = [];
                    let locationFemaleAgeGroup4Non: IReportingFile[] = [];
                    let locationFemaleAgeGroup5Indigenous: IReportingFile[] = [];
                    let locationFemaleAgeGroup5Non: IReportingFile[] = [];

                    let locationOtherAgeGroup1Indigenous: IReportingFile[] = [];
                    let locationOtherAgeGroup1Non: IReportingFile[] = [];
                    let locationOtherAgeGroup2Indigenous: IReportingFile[] = [];
                    let locationOtherAgeGroup2Non: IReportingFile[] = [];
                    let locationOtherAgeGroup3Indigenous: IReportingFile[] = [];
                    let locationOtherAgeGroup3Non: IReportingFile[] = [];
                    let locationOtherAgeGroup4Indigenous: IReportingFile[] = [];
                    let locationOtherAgeGroup4Non: IReportingFile[] = [];
                    let locationOtherAgeGroup5Indigenous: IReportingFile[] = [];
                    let locationOtherAgeGroup5Non: IReportingFile[] = [];

                    locationFiles.forEach((f) => {
                      const client = individualsContext.state.individuals.find((i) => i.individualId === f.cId);
                      if (client) {
                        const age = client.birthDate
                          ? dateDiffInYears(new Date(client.birthDate), new Date(f.appDate))
                          : undefined;
                        if (client.genderId && client.genderId === GENDER_MALE) {
                          if (age !== undefined && age < 18) {
                            if (client.indigenous || client.visibleMinority) {
                              locationMaleAgeGroup1Indigenous = locationMaleAgeGroup1Indigenous.concat(f);
                            } else {
                              locationMaleAgeGroup1Non = locationMaleAgeGroup1Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 18 && age <= 29) {
                            if (client.indigenous || client.visibleMinority) {
                              locationMaleAgeGroup2Indigenous = locationMaleAgeGroup2Indigenous.concat(f);
                            } else {
                              locationMaleAgeGroup2Non = locationMaleAgeGroup2Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 30 && age <= 34) {
                            if (client.indigenous || client.visibleMinority) {
                              locationMaleAgeGroup3Indigenous = locationMaleAgeGroup3Indigenous.concat(f);
                            } else {
                              locationMaleAgeGroup3Non = locationMaleAgeGroup3Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 35 && age <= 49) {
                            if (client.indigenous || client.visibleMinority) {
                              locationMaleAgeGroup4Indigenous = locationMaleAgeGroup4Indigenous.concat(f);
                            } else {
                              locationMaleAgeGroup4Non = locationMaleAgeGroup4Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 50) {
                            if (client.indigenous || client.visibleMinority) {
                              locationMaleAgeGroup5Indigenous = locationMaleAgeGroup5Indigenous.concat(f);
                            } else {
                              locationMaleAgeGroup5Non = locationMaleAgeGroup5Non.concat(f);
                            }
                          }
                        } else if (client.genderId && client.genderId === GENDER_FEMALE) {
                          if (age !== undefined && age < 18) {
                            if (client.indigenous || client.visibleMinority) {
                              locationFemaleAgeGroup1Indigenous = locationFemaleAgeGroup1Indigenous.concat(f);
                            } else {
                              locationFemaleAgeGroup1Non = locationFemaleAgeGroup1Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 18 && age <= 29) {
                            if (client.indigenous || client.visibleMinority) {
                              locationFemaleAgeGroup2Indigenous = locationFemaleAgeGroup2Indigenous.concat(f);
                            } else {
                              locationFemaleAgeGroup2Non = locationFemaleAgeGroup2Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 30 && age <= 34) {
                            if (client.indigenous || client.visibleMinority) {
                              locationFemaleAgeGroup3Indigenous = locationFemaleAgeGroup3Indigenous.concat(f);
                            } else {
                              locationFemaleAgeGroup3Non = locationFemaleAgeGroup3Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 35 && age <= 49) {
                            if (client.indigenous || client.visibleMinority) {
                              locationFemaleAgeGroup4Indigenous = locationFemaleAgeGroup4Indigenous.concat(f);
                            } else {
                              locationFemaleAgeGroup4Non = locationFemaleAgeGroup4Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 50) {
                            if (client.indigenous || client.visibleMinority) {
                              locationFemaleAgeGroup5Indigenous = locationFemaleAgeGroup5Indigenous.concat(f);
                            } else {
                              locationFemaleAgeGroup5Non = locationFemaleAgeGroup5Non.concat(f);
                            }
                          }
                        } else if (client.genderId && client.genderId === GENDER_OTHER) {
                          if (age !== undefined && age < 18) {
                            if (client.indigenous || client.visibleMinority) {
                              locationOtherAgeGroup1Indigenous = locationOtherAgeGroup1Indigenous.concat(f);
                            } else {
                              locationOtherAgeGroup1Non = locationOtherAgeGroup1Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 18 && age <= 29) {
                            if (client.indigenous || client.visibleMinority) {
                              locationOtherAgeGroup2Indigenous = locationOtherAgeGroup2Indigenous.concat(f);
                            } else {
                              locationOtherAgeGroup2Non = locationOtherAgeGroup2Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 30 && age <= 34) {
                            if (client.indigenous || client.visibleMinority) {
                              locationOtherAgeGroup3Indigenous = locationOtherAgeGroup3Indigenous.concat(f);
                            } else {
                              locationOtherAgeGroup3Non = locationOtherAgeGroup3Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 35 && age <= 49) {
                            if (client.indigenous || client.visibleMinority) {
                              locationOtherAgeGroup4Indigenous = locationOtherAgeGroup4Indigenous.concat(f);
                            } else {
                              locationOtherAgeGroup4Non = locationOtherAgeGroup4Non.concat(f);
                            }
                          } else if (age !== undefined && age >= 50) {
                            if (client.indigenous || client.visibleMinority) {
                              locationOtherAgeGroup5Indigenous = locationOtherAgeGroup5Indigenous.concat(f);
                            } else {
                              locationOtherAgeGroup5Non = locationOtherAgeGroup5Non.concat(f);
                            }
                          }
                        }
                      }
                    });
                    const locationMale = locationMaleAgeGroup1Indigenous
                      .concat(locationMaleAgeGroup1Non)
                      .concat(locationMaleAgeGroup2Indigenous)
                      .concat(locationMaleAgeGroup2Non)
                      .concat(locationMaleAgeGroup3Indigenous)
                      .concat(locationMaleAgeGroup3Non)
                      .concat(locationMaleAgeGroup4Indigenous)
                      .concat(locationMaleAgeGroup4Non)
                      .concat(locationMaleAgeGroup5Indigenous)
                      .concat(locationMaleAgeGroup5Non);
                    const locationFemale = locationFemaleAgeGroup1Indigenous
                      .concat(locationFemaleAgeGroup1Non)
                      .concat(locationFemaleAgeGroup2Indigenous)
                      .concat(locationFemaleAgeGroup2Non)
                      .concat(locationFemaleAgeGroup3Indigenous)
                      .concat(locationFemaleAgeGroup3Non)
                      .concat(locationFemaleAgeGroup4Indigenous)
                      .concat(locationFemaleAgeGroup4Non)
                      .concat(locationFemaleAgeGroup5Indigenous)
                      .concat(locationFemaleAgeGroup5Non);
                    const locationOther = locationOtherAgeGroup1Indigenous
                      .concat(locationOtherAgeGroup1Non)
                      .concat(locationOtherAgeGroup2Indigenous)
                      .concat(locationOtherAgeGroup2Non)
                      .concat(locationOtherAgeGroup3Indigenous)
                      .concat(locationOtherAgeGroup3Non)
                      .concat(locationOtherAgeGroup4Indigenous)
                      .concat(locationOtherAgeGroup4Non)
                      .concat(locationOtherAgeGroup5Indigenous)
                      .concat(locationOtherAgeGroup5Non);

                    return (
                      <>
                        <tr>
                          <th className={'header-side-bottom'} rowSpan={9}>
                            {c.name}
                          </th>
                          <th className={'header-side'}>
                            <b>Received</b>
                          </th>
                          <td className={'total'}>{locationMaleAgeGroup1Indigenous.length}</td>
                          <td className={'total'}>{locationMaleAgeGroup1Non.length}</td>
                          <td className={'total'}>{locationMaleAgeGroup2Indigenous.length}</td>
                          <td className={'total'}>{locationMaleAgeGroup2Non.length}</td>
                          <td className={'total'}>{locationMaleAgeGroup3Indigenous.length}</td>
                          <td className={'total'}>{locationMaleAgeGroup3Non.length}</td>
                          <td className={'total'}>{locationMaleAgeGroup4Indigenous.length}</td>
                          <td className={'total'}>{locationMaleAgeGroup4Non.length}</td>
                          <td className={'total'}>{locationMaleAgeGroup5Indigenous.length}</td>
                          <td className={'total'}>{locationMaleAgeGroup5Non.length}</td>
                          <td className={'total'}>
                            <b>{locationMale.length}</b>
                          </td>
                          <td className={'total'}>{locationFemaleAgeGroup1Indigenous.length}</td>
                          <td className={'total'}>{locationFemaleAgeGroup1Non.length}</td>
                          <td className={'total'}>{locationFemaleAgeGroup2Indigenous.length}</td>
                          <td className={'total'}>{locationFemaleAgeGroup2Non.length}</td>
                          <td className={'total'}>{locationFemaleAgeGroup3Indigenous.length}</td>
                          <td className={'total'}>{locationFemaleAgeGroup3Non.length}</td>
                          <td className={'total'}>{locationFemaleAgeGroup4Indigenous.length}</td>
                          <td className={'total'}>{locationFemaleAgeGroup4Non.length}</td>
                          <td className={'total'}>{locationFemaleAgeGroup5Indigenous.length}</td>
                          <td className={'total'}>{locationFemaleAgeGroup5Non.length}</td>
                          <td className={'total'}>
                            <b>{locationFemale.length}</b>
                          </td>
                          <td className={'total'}>{locationOtherAgeGroup1Indigenous.length}</td>
                          <td className={'total'}>{locationOtherAgeGroup1Non.length}</td>
                          <td className={'total'}>{locationOtherAgeGroup2Indigenous.length}</td>
                          <td className={'total'}>{locationOtherAgeGroup2Non.length}</td>
                          <td className={'total'}>{locationOtherAgeGroup3Indigenous.length}</td>
                          <td className={'total'}>{locationOtherAgeGroup3Non.length}</td>
                          <td className={'total'}>{locationOtherAgeGroup4Indigenous.length}</td>
                          <td className={'total'}>{locationOtherAgeGroup4Non.length}</td>
                          <td className={'total'}>{locationOtherAgeGroup5Indigenous.length}</td>
                          <td className={'total'}>{locationOtherAgeGroup5Non.length}</td>
                          <td className={'total'}>
                            <b>{locationOther.length}</b>
                          </td>
                          <td className={'total'}>
                            <b>{locationMale.concat(locationFemale).concat(locationOther).length}</b>
                          </td>
                        </tr>
                        <tr>
                          <th className={'header-side'}>
                            <b>Approved</b>
                          </th>
                          <td className={'total'}>
                            {locationMaleAgeGroup1Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationMaleAgeGroup1Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationMaleAgeGroup2Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationMaleAgeGroup2Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationMaleAgeGroup3Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationMaleAgeGroup3Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationMaleAgeGroup4Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationMaleAgeGroup4Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationMaleAgeGroup5Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationMaleAgeGroup5Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            <b>{locationMale.filter((f) => approvedStages.includes(f.sId)).length}</b>
                          </td>
                          <td className={'total'}>
                            {locationFemaleAgeGroup1Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationFemaleAgeGroup1Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationFemaleAgeGroup2Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationFemaleAgeGroup2Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationFemaleAgeGroup3Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationFemaleAgeGroup3Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationFemaleAgeGroup4Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationFemaleAgeGroup4Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationFemaleAgeGroup5Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationFemaleAgeGroup5Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            <b>{locationFemale.filter((f) => approvedStages.includes(f.sId)).length}</b>
                          </td>
                          <td className={'total'}>
                            {locationOtherAgeGroup1Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationOtherAgeGroup1Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationOtherAgeGroup2Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationOtherAgeGroup2Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationOtherAgeGroup3Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationOtherAgeGroup3Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationOtherAgeGroup4Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationOtherAgeGroup4Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationOtherAgeGroup5Indigenous.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            {locationOtherAgeGroup5Non.filter((f) => approvedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total'}>
                            <b>{locationOther.filter((f) => approvedStages.includes(f.sId)).length}</b>
                          </td>
                          <td className={'total'}>
                            <b>
                              {
                                locationMale
                                  .concat(locationFemale)
                                  .concat(locationOther)
                                  .filter((f) => approvedStages.includes(f.sId)).length
                              }
                            </b>
                          </td>
                        </tr>
                        {domainContext.reasons.map((reason: IReason) => {
                          return (
                            <tr key={reason.reasonId}>
                              <th className={'header-side'}>{'Refused - ' + reason.name}</th>
                              <td className={'report-row'}>
                                {
                                  locationMaleAgeGroup1Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationMaleAgeGroup1Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationMaleAgeGroup2Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationMaleAgeGroup2Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationMaleAgeGroup3Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationMaleAgeGroup3Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationMaleAgeGroup4Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationMaleAgeGroup4Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationMaleAgeGroup5Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationMaleAgeGroup5Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'total'}>
                                <b>{locationMale.filter((f) => isRefusedReason(f, reason.reasonId)).length}</b>
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationFemaleAgeGroup1Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationFemaleAgeGroup1Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationFemaleAgeGroup2Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationFemaleAgeGroup2Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationFemaleAgeGroup3Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationFemaleAgeGroup3Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationFemaleAgeGroup4Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationFemaleAgeGroup4Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationFemaleAgeGroup5Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationFemaleAgeGroup5Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'total'}>
                                <b>{locationFemale.filter((f) => isRefusedReason(f, reason.reasonId)).length}</b>
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationOtherAgeGroup1Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationOtherAgeGroup1Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationOtherAgeGroup2Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationOtherAgeGroup2Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationOtherAgeGroup3Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationOtherAgeGroup3Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationOtherAgeGroup4Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationOtherAgeGroup4Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'report-row'}>
                                {
                                  locationOtherAgeGroup5Indigenous.filter((f) => isRefusedReason(f, reason.reasonId))
                                    .length
                                }
                              </td>
                              <td className={'report-row'}>
                                {locationOtherAgeGroup5Non.filter((f) => isRefusedReason(f, reason.reasonId)).length}
                              </td>
                              <td className={'total'}>
                                <b>{locationOther.filter((f) => isRefusedReason(f, reason.reasonId)).length}</b>
                              </td>
                              <td className={'total'}>
                                <b>
                                  {
                                    locationMale
                                      .concat(locationFemale)
                                      .concat(locationOther)
                                      .filter((f) => isRefusedReason(f, reason.reasonId)).length
                                  }
                                </b>
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <th className={'header-side-bottom'}>
                            <b>Total Refused</b>
                          </th>
                          <td className={'total-bottom'}>
                            {locationMaleAgeGroup1Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationMaleAgeGroup1Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationMaleAgeGroup2Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationMaleAgeGroup2Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationMaleAgeGroup3Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationMaleAgeGroup3Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationMaleAgeGroup4Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationMaleAgeGroup4Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationMaleAgeGroup5Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationMaleAgeGroup5Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationMale.filter((f) => refusedStages.includes(f.sId)).length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            {locationFemaleAgeGroup1Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationFemaleAgeGroup1Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationFemaleAgeGroup2Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationFemaleAgeGroup2Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationFemaleAgeGroup3Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationFemaleAgeGroup3Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationFemaleAgeGroup4Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationFemaleAgeGroup4Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationFemaleAgeGroup5Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationFemaleAgeGroup5Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            <b> {locationFemale.filter((f) => refusedStages.includes(f.sId)).length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            {locationOtherAgeGroup1Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationOtherAgeGroup1Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationOtherAgeGroup2Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationOtherAgeGroup2Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationOtherAgeGroup3Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationOtherAgeGroup3Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationOtherAgeGroup4Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationOtherAgeGroup4Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationOtherAgeGroup5Indigenous.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            {locationOtherAgeGroup5Non.filter((f) => refusedStages.includes(f.sId)).length}
                          </td>
                          <td className={'total-bottom'}>
                            <b>{locationOther.filter((f) => refusedStages.includes(f.sId)).length}</b>
                          </td>
                          <td className={'total-bottom'}>
                            <b>
                              {
                                locationMale
                                  .concat(locationFemale)
                                  .concat(locationOther)
                                  .filter((f) => refusedStages.includes(f.sId)).length
                              }
                            </b>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                {genericTable()}
              </>
            ) : (
              genericTable()
            )}
          </tbody>
        </table>
      </div>
      {missingDataFiles.length > 0 && (
        <div className={'px-5 pb-3 no-print'}>
          <p>Files missing data: </p>
          <ul>
            {missingDataFiles.map((f) => (
              <li key={f.fId}>
                {myUserContext.isReportingOnly ? (
                  <div>{f.fNum + (f.fSeqNum > 1 ? '-' + f.fSeqNum : '')}</div>
                ) : (
                  <Link to={'/files/' + f.fId}>{f.fNum + (f.fSeqNum > 1 ? '-' + f.fSeqNum : '')}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {refusedFiles.length > 0 && (
        <div className={'px-5 pb-3 no-print'}>
          <p>Refused files: </p>
          <ul>
            {refusedFiles.map((f) => (
              <li key={f.fId}>
                {myUserContext.isReportingOnly ? (
                  <div>{f.fNum + (f.fSeqNum > 1 ? '-' + f.fSeqNum : '')}</div>
                ) : (
                  <Link to={'/files/' + f.fId}>{f.fNum + (f.fSeqNum > 1 ? '-' + f.fSeqNum : '')}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
